import { actionTypes } from "../constants/actionTypes";

const initialState = {
    project: null,
    number: null,
    description: null,
    loading: false,
    error: false,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.getProjectSuccess:
            return {
                ...state,
                project: action.payload,
                number: action.payload[0].ProjectId,
                description: action.payload[0].Description,
                loading: false,
                error: false,
            };
        case 'LOADING_PROJECT':
            return {
                ...state,
                loading: action.payload,
            };
        case 'ERROR_PROJECT':
            return {
                ...state,
                loading: false,
                description: action.payload,
                error: true,
            };
        case 'UNSELECT_PROJECT':
            return {
                ...state,
                description: null,
                number: null,
            };
        default:
            return state;
    }
}