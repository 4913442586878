import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import AuthenticationService from '../services/authenticationService';
import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const loginCheck = (username, password) => {
    return async (dispatch) => {
        try {
            let body = "_username=" + username + "&_password=" + password;
            let url = apiRoutes.authenticationRelated.getToken;
            let requestData = await ApiService.performRequest(RequestType.POST, url, body);
            let { token } = requestData;

            await AuthenticationService.setToken(token);

            dispatch({type: actionTypes.loginSuccess})
        } catch(error) {
            alert('Verkeerde inloggegevens. Probeer het opnieuw.')
        }
    }
};

// const _loginCheckSuccess = () => {
//     return {
//         type: "LOGIN_SUCCESS"
//     }
// };
//
// const _getUserSuccess = (response) => {
//     return {
//         type: actionTypes.getUserSuccess,
//         payload: response
//     }
// };

const logout = () => {
    AuthenticationService.logout();

    return {
        type: actionTypes.logout
    }
};

export default {
    loginCheck,
    logout,
}
