import decode from 'jwt-decode';

export default class AuthenticationService {

    static setToken(token) {
        return localStorage.setItem('token', token);
    }

    static loggedIn() {
        const token = localStorage.getItem('token');

        return !!token && !this.isTokenExpired(token);
    }

    static isTokenExpired(token) {
        try {
            const decoded = decode(token);

            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            return false;

        } catch (error) {
            return false;
        }
    }

    static logout() {
        localStorage.clear();
    }

}