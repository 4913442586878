import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from "./containers/Pages/Login/Login";
import { Provider, connect } from 'react-redux';
import {bindActionCreators} from "redux";
import store from './store';
import userActions from './actions/userActions';
import Dashboard from "./containers/Layout/Dashboard";

import {toast} from "react-toastify";
import ThankyouPage from "./containers/Thankyou";

const pathName = window.location.pathname;

class AppRouter extends React.Component {
    componentDidMount() {
        this.props.userActions.getUser();
    }

    render() {
        const { authentication } = this.props;

        // configure toast
        toast.configure();


        if (pathName === '/thankyou') {
            return (
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/thankyou"  name="Thankyou" component={ThankyouPage}/>
                    </Switch>
                </BrowserRouter>
            )
        }

        return (
            <BrowserRouter>
                {authentication.isLoggedIn ?
                    <Switch>
                        <Route exact path="/*" name="Home" component={Dashboard} />
                    </Switch> :
                    <Switch>
                        <Route path="/" name="Login" component={Login} />
                    </Switch>
                }
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    };
};

const AppRouterContainer = connect(mapStateToProps, mapDispatchToProps)(AppRouter);

const App = () => (
    <Provider store={store}>
        <AppRouterContainer/>
    </Provider>
);

export default App;
