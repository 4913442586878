const actionTypes = {
    // authentication related
    loginSuccess:   "LOGIN_SUCCESS",
    loginFailed:    "LOGIN_FAILED",
    logout:         "LOGOUT",

    // User
    getUserSuccess: "GET_USER_SUCCESS",
    getUsersListSuccess: 'GET_USERS_LIST_SUCCESS',
    getUsersListFailed: 'GET_USERS_LIST_FAILED',

    // Warehouse related
    getWarehouses:          "GET_WAREHOUSES",
    getWarehousesSuccess:   "GET_WAREHOUSEs_SUCCESS",

    //Goods received
    postGoodsReceivedSuccess:       "POST_GOODS_RECEIVED_SUCCESS",
    editGoodsReceivedSuccess:       "EDIT_GOODS_RECEIVED_SUCCESS",
    getGoodsreceivedSuccess:        "GET_GOODSRECEIVED_SUCCESS",
    getGoodsreceivedDoneSuccess:    "GET_GOODSRECEIVED_DONE_SUCCESS",
    syncGoodsreceivedSuccess:       "SYNC_GOODSRECEIVED_SUCCESS",
    syncGoodsreceivedFailed:        "SYNC_GOODSRECEIVED_FAILED",

    // Articles
    getArticles:                    'GET_ARTICLES',
    getArticlesSuccess:             'GET_ARTICLES_SUCCESS',
    getArticlesByItemcode:          'GET_ARTICLES_BY_ITEMCODE',
    getArticlesByItemcodeSuccess:   'GET_ARTICLES_BY_ITEMCODE_SUCCESS',
    syncArticles:                   'SYNC_ARTICLES',
    syncArticlesSuccess:            'SYNC_ARTICLES_SUCCESS',

    // projects
    getProject:         'GET_PROJECT',
    getProjectSuccess:  'GET_PROJECT_SUCCESS',

    // Warehouse
    getWarehouse:           'GET_WAREHOUSE',
    getWarehouseSuccess:    'GET_WAREHOUSE_SUCCESS',

    // items related
    getByItemcode: 'GET_BY_ITEMCODE',
    getByItemcodeSuccess: 'GET_BY_ITEMCODE_SUCCESS',
};

const authenticationActionTypes = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'LOGOUT_FAILED',
};

const articleActionTypes = {

};

const ontvangstActionTypes = {

    GET_ONTVANGSTEN_SUCCESS: 'GET_ONTVANGSTEN_SUCCESS',
    GET_ONTVANGSTEN_FAILED: 'GET_ONTVANGSTEN_FAILED',

    POST_ONTVANGST_SUCCESS: 'POST_ONTVANGST_SUCCESS',
    POST_ONTVANGST_FAILED: 'POST_ONTVANGST_FAILED',

    ADD_ONTVANGST: 'ADD_ONTVANGST',

    SEND_ONTVANGST_SUCCESS: 'SEND_ONTVANGST_SUCCESS',
    SEND_ONTVANGST_FAILED: 'SEND_ONTVANGST_FAILED',

};

const wrappingScanActionTypes = {
    GET_WRAPPING_SCANS_PENDING: 'GET_WRAPPING_SCANS_PENDING',
    GET_WRAPPING_SCANS_SUCCESS: 'GET_WRAPPING_SCANS_SUCCESS',
    GET_WRAPPING_SCANS_FAILED: 'GET_WRAPPING_SCANS_FAILED',

    PATCH_WRAPPING_SCANS_PENDING: 'PATCH_WRAPPING_SCANS_PENDING',
    PATCH_WRAPPING_SCANS_SUCCESS: 'PATCH_WRAPPING_SCANS_SUCCESS',
    PATCH_WRAPPING_SCANS_FAILED: 'PATCH_WRAPPING_SCANS_FAILED',

    POST_WRAPPING_SCANS_PENDING: 'POST_WRAPPING_SCANS_PENDING',
    POST_WRAPPING_SCANS_SUCCESS: 'POST_WRAPPING_SCANS_SUCCESS',
    POST_WRAPPING_SCANS_FAILED: 'POST_WRAPPING_SCANS_FAILED',

    PATCH_STATUS_IN_QEUE_SUCCESS: 'PATCH_STATUS_IN_QEUE_SUCCESS',
    PATCH_STATUS_IN_QEUE_FAILED: 'PATCH_STATUS_IN_QEUE_FAILED',

};

const picklistTypes = {
    FETCH: 'FETCH',
    FETCH_SUCCESS: 'FETCH_SUCCESS',
    FETCH_FAILURE: 'FETCH_FAILURE',

    patchPicklists: 'PATCH_PICKLISTS',
    patchPicklistsSuccess: 'PATCH_PICKLISTS_SUCCESS',
    patchPicklistsFailed: 'PATCH_PICKLISTS_FAILED',
};

export {
    actionTypes,
    ontvangstActionTypes,
    authenticationActionTypes,
    articleActionTypes,
    wrappingScanActionTypes,
    picklistTypes,
}
