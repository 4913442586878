import { actionTypes } from "../constants/actionTypes";
import { apiRoutes } from "../constants/apiRoutes";
import ApiService from "../services/apiService";
import RequestType from "../constants/requestType";


const getUser = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.userRelated.user;
            let response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getUserSuccess(response))

        } catch (error) {
            // console.log('error', 'Fout tijdens het ophalen van gebruiker.')
        }
    }
};

const getUsersList = () => {
    return async dispatch => {
        try {
            const url = apiRoutes.userRelated.getPickers;
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch({
                type: actionTypes.getUsersListSuccess,
                payload: response,
            })
        } catch (error) {
            dispatch({ type: actionTypes.getUsersListFailed })
        }
    }
}

const _getUserSuccess = (response) => {
    return {
        type: actionTypes.getUserSuccess,
        payload: response,
    };
};

export default {
    getUser,
    getUsersList,
}
