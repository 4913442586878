const apiRoutes = {
    // rootUrl: 'http://192.168.33.10/api/',
    rootUrl: 'https://backend.ifits.online/api/',

    // Authentication
    authenticationRelated: {
        login:      'login',
        getToken:   'login_check',
    },

    // User
    userRelated: {
        user: 'user',
        getList: 'users',
        getPickers: 'users/pickers',
    },

    // Warehouse
    warehouseRelated: {
        list:               'warehouseList',
        addEdit:            'warehouse',
        deleteWarehouse:    'warehouse',
        get:                'warehouse'
    },

    // GoodsReceivedRelated
    goodsReceivedRelated: {
        add:        'goods_received',
        edit:       'goods_received',
        get:        'goods_received',
        getDone:    'goods_received_done',
        sync:       'processgoods',
    },
    // Inbound
    inboundRelated: {
        articlesByWarehouse: 'articlesByWarehouse'
    },
    // Articles
    articlesRelated: {
        articlesByWarehouse:    'articles_by_warehouse',
        articleByItemcode:      'articles_by_itemcode',
        syncByWarehouse:        'article'
    },
    // Project
    projectRelated: {
        get: 'project'
    },

    wrappingScanRelated: {
        get: 'wrapping_scans',
        post: 'wrapping_scans',
        patch: 'wrapping_scans/{id}',
        request: 'wrapping_scans',
        patchStatus: 'wrapping_scans/patch_status/{id}'
    },

    picklists: {
        fetch: 'deliverynotes/dashboard',
        patch: 'deliverynotes',
        // patch: 'deliverynotes/{picklistId}',
    },
};

export {
    apiRoutes
}
