// import { actionTypes } from "../constants/actionTypes";

import { actionTypes } from "../constants/actionTypes";

const initialState = {
    allIds: [],
    byId: {},
    user: [],
    list: {}
};

export default (state = initialState, action) => {
    switch(action.type) {
        case "GET_USER_SUCCESS":
            return {
                ...state,
                user: action.payload,
            };
        case actionTypes.getUsersListSuccess:
            let byId = { ...state.byId };
            let list = { ...state.list }
            action.payload.map(user => {
                byId[user.id] = user;
                list[user.id] = user.username
            })

            return {
                ...state,
                allIds: Object.keys(byId),
                byId: byId,
                list: list,
            }
        case actionTypes.getUsersListFailed:
            return {
                ...state,
            }
        default:
            return state;
    }
}
