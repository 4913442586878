import { wrappingScanActionTypes } from "../constants/actionTypes";


const initialState = {
    allIds: [],
    byId: {},
};

export default (state = initialState, action) => {
    switch(action.type) {
        case wrappingScanActionTypes.GET_WRAPPING_SCANS_PENDING:

            return {

            };
        case wrappingScanActionTypes.GET_WRAPPING_SCANS_SUCCESS:
            let byId = {...state.byId};

            action.payload.map(item => {
                byId[item.id] = item;

                return item;
            });

            return {
                allIds: Object.keys(byId),
                byId: byId
            };
        case wrappingScanActionTypes.POST_WRAPPING_SCANS_PENDING:
            return {

            };
        case wrappingScanActionTypes.POST_WRAPPING_SCANS_SUCCESS:
            return {

            };
        default:
            return state;
    }
}
