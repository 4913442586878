import { normalize, schema } from 'normalizr';

const itemSchema = new schema.Entity('items', {}, {idAttribute: 'itemcode'});

const initialState = {
    items: normalize([], [itemSchema]),
};

export default (state = initialState, action) => {
    switch(action.type) {
        case "GET_ARTICLES_SUCCESS":

            return {
                ...state,
                items: normalize(action.payload, [itemSchema]),
            };
        case "GET_BY_ITEMCODE_SUCCESS":
            return {
                ...state,
                articles: action.payload,
            };
        case "ADD_ARTICLES":
            return {
                ...state,
                goods: [...state.goods, action.payload]
            };
        default:
            return state;
    }
}