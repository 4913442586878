import React from 'react';
import { Formik } from "formik";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import authenticationActions from "../../../actions/authenticationActions";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: ''
        };
    }

    login = () => {
        const { username, password } = this.state;
        this.props.authenticationActions.loginCheck(username, password);
    };

    render() {
        return (
            <div className="App">
                <Container style={{marginTop: '125px'}}>

                    <Row>
                        <Col xs={'12'} sm={{ span: 6, offset: 3 }}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="text-center">Inloggen iFITS Mobile</Card.Title>

                                    <Formik
                                        initialValues={{ username: '', password: '' }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            setSubmitting(false);

                                            this.setState({
                                                username: values.username,
                                                password: values.password
                                            });

                                            this.login();
                                        }}
                                    >
                                        {({
                                              values,
                                              handleChange,
                                              handleBlur,
                                              handleSubmit,
                                          }) => (
                                            <Form onSubmit={handleSubmit} className="login-form">
                                                <Form.Group>
                                                    <Form.Label>Gebruikersnaam</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="username"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.username}
                                                        // placeholder="Gebruikersnaam"
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Wachtwooord</Form.Label>

                                                    <Form.Control
                                                        type="password"
                                                        name="password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                        // placeholder="Wachtwoord"
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Button type="primary" className="login-form-button">
                                                        Inloggen
                                                    </Button>
                                                </Form.Group>
                                            </Form>
                                        )}
                                    </Formik>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = ({ authentication }) => {
    return {
        authentication,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
