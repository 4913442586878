import { picklistTypes } from "../constants/actionTypes";

const initialState = {
    allIds: [],
    byId: {},
    isLoading: false,
};

export default (state = initialState, action) => {
    switch(action.type) {
        case picklistTypes.FETCH:
            return {
                ...state,
                isLoading: true,
            }
        case picklistTypes.FETCH_SUCCESS:
            let byId = {...state.byId};
            action.payload.map(item => {
                byId[item.id] = item;
                return item;
            })

            return {
                ...state,
                allIds: Object.keys(byId),
                byId: byId,
                isLoading: false,
            }
        case picklistTypes.FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case picklistTypes.patchPicklists:
            return {
                ...state,
                isLoading: true,
            };
        case picklistTypes.patchPicklistsSuccess:
            return {
                ...state,
                isLoading: false,
                // byId: {
                //     ...state.byId,
                //     [action.payload.id]: {
                //         ...state.byId[action.payload.id],
                //         picker: action.payload.picker.id,
                //         priority: action.payload.priority,
                //         status: action.payload.status
                //     },
                // },
            };
        case picklistTypes.patchPicklistsFailed:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}
