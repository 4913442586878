import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
    return <div>Laden...</div>;
}

const ChooseMenu = Loadable({
    loader: () => import('./containers/Layout/ChooseMenu'),
    loading: Loading,
});

const EditWrappingScan = Loadable({
    loader: () => import('./containers/WrappingScan/EditWrappingScan'),
    loading: Loading,
});

const WrappingScanOverview = Loadable({
    loader: () => import('./containers/WrappingScan/WrappingScanOverview'),
    loading: Loading,
});

const Ontvangst = Loadable({
    loader: () => import('./containers/Ontvangst/EditOntvangst'),
    loading: Loading,
});

const OpenstaandeOntvangsten = Loadable({
    loader: () => import('./containers/Overzicht/OpenstaandeOntvangsten'),
    loading: Loading,
});
const VoltooideOntvangsten = Loadable({
    loader: () => import('./containers/Overzicht/VoltooideOntvangsten'),
    loading: Loading,
});

const VoltooideOntvangst = Loadable({
    loader: () => import('./containers/Ontvangst/ShowOntvangst'),
    loading: Loading,
});


const EditGoodsReceipt = Loadable({
    loader: () => import('./containers/GoodsReceipt/EditGoodsReceipt'),
    loading: Loading,
});
const GoodsReceiptOverview = Loadable({
    loader: () => import('./containers/GoodsReceipt/Overview/GoodsReceiptOverview'),
    loading: Loading,
});

const Picklists = Loadable({
    loader: () => import('./containers/Picklists/Picklists'),
    loading: Loading,
});

const routes = [
    { path: '/', exact: true, name: 'Kies sectie', component: ChooseMenu },

    // Wrapping scans
    { path: '/ompakken', exact: true, name: 'Ompakken Quooker', component: WrappingScanOverview },
    { path: '/ompak', exact: true, name: 'Ompakken Quooker', component: EditWrappingScan },


    // ontvangsten
    { path: '/ontvangst', exact: true, name: 'Ontvangst', component: Ontvangst },
    { path: '/ontvangsten', exact: true, name: 'openstaande ontvangsten', component: OpenstaandeOntvangsten },
    { path: '/voltooide', exact: true, name: 'Voltooide ontvangsten', component: VoltooideOntvangsten },
    { path: '/ontvangst/bekijk', exact: true, name: 'Voltooide ontvangst', component: VoltooideOntvangst },

    // nieuwe ontvangsten met 1 barcode
    { path: '/goods_receipt', exact: true, name: 'overzicht', component: GoodsReceiptOverview },
    { path: '/goods_receipt/edit', exact: true, name: 'ontvangst', component: EditGoodsReceipt },
    // Picklists
    { path: '/picklists', exact: true, name: 'pakbonnen', component: Picklists },

];

export default routes;
