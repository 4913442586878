import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import nlFlag from '../../assets/nl.svg';
import enFlag from '../../assets/en.svg';
import Dropdown from "react-bootstrap/Dropdown";

const ThankyouPage = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const lang = urlParams.get('lang');

    const [language, setLanguage] = useState(lang);

    return (
        <Container style={{marginTop: '25px'}}>
            <Row className="justify-content-end">
                <Col xs="2">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <img src={language === 'nl' ? nlFlag : enFlag} alt="" width={32} />
                            {language === 'nl' ? ' Nederlands' : ' English'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setLanguage(language === 'nl' ? 'en' : 'nl')
                                }}
                            >
                                <img src={language === 'nl' ? enFlag : nlFlag} alt="" width={32} />
                                {language === 'nl' ? ' English' : ' Nederlands'}
                            </Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>


            <Row className="justify-content-md-center">
                <Col xs="6">
                    <p className="lead">
                        {language === 'nl'
                            ? 'Bedankt voor uw opdracht en wij nemen zo snel mogelijk contact met u op.'
                            : 'Many thanks for the order and we will contact you asap.'
                        }
                    </p>
                </Col>
            </Row>

        </Container>
    )
}

export default ThankyouPage;
