import axios from 'axios';
import RequestType from '../constants/requestType';
import { apiRoutes } from "../constants/apiRoutes";

export default class ApiService {

    static ROOT_URL = apiRoutes.rootUrl;

    static async performRequest(type, url, data = null) {

        switch(type) {
            case RequestType.GET:
               return this.getRequest(url);
            case RequestType.PATCH:
                return this.patchRequest(url, data);
            case RequestType.POST:
                return this.postRequest(url, data);
            case RequestType.DELETE:
                return this.deleteRequest(url, data);
            default:
                return null;
        }
    }

    static getRequest = async (url) => {
        const token = localStorage.getItem('token');
        // let request = await axios.get(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
        let request = await axios.get(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`} });

        return request.data;
    };

    static patchRequest = async (url, data) => {
        const token = localStorage.getItem('token');

        let request = await axios.patch(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
        return request.data;
    };

    static postRequest = async (url, data) => {
        const token = localStorage.getItem('token');

        let request = await axios.post(ApiService.ROOT_URL + url, data, {headers: {'Authorization' : `Bearer ${token}`}});
        return request.data;
    };

    static deleteRequest = async (url, data) => {
        const token = localStorage.getItem('token');

        let request = await axios.delete(ApiService.ROOT_URL + url, {headers: {'Authorization' : `Bearer ${token}`}});
        return request.data;
    };
}
