import React from 'react';

import {Route, Switch} from "react-router-dom";
import routes from "../../routes";

import { connect } from 'react-redux';
import Container from "react-bootstrap/Container";


class Dashboard extends React.Component {
    render() {
        return (
            <div className="App">

                <Container fluid={true}>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                                <Route key={idx}
                                       path={route.path}
                                       exact={route.exact}
                                       name={route.name}
                                       render={props => (<route.component {...props} />)} />) : null;
                            },
                        )}
                    </Switch>
                </Container>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authentication: state.authentication,
    }
};

export default connect(mapStateToProps, null)(Dashboard);
