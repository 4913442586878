import { combineReducers } from 'redux';

import authentication from './authenticationReducer';
import users from './userReducer';
import warehouse from './warehouseReducer';
import project from './projectReducer';
import item from './itemReducer';
import ontvangst from './ontvangstReducer';
import wrappingScans from './wrappingScanReducer';
import picklists from './picklistReducer';

export default combineReducers({
    authentication,
    item,
    ontvangst,
    picklists,
    project,
    users,
    warehouse,
    wrappingScans
});
