import { actionTypes } from "../constants/actionTypes";
import AuthenticationService from '../services/authenticationService';

const initialState = {
    isLoggedIn: AuthenticationService.loggedIn(),
    loginError: 'Login error',
};

export default (state = initialState, action) => {
    switch(action.type) {
        case "LOGIN_SUCCESS":
            return {
                ...state,
                isLoggedIn: true,
                loginError: '',
            };
        case actionTypes.logout:
            return {
                ...state,
                isLoggedIn: false
            };
        default:
            return state;
    }
}