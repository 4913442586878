import { ontvangstActionTypes } from "../constants/actionTypes";
import { normalize, schema } from 'normalizr';

const initialState = {
    ontvangstID: null,
    goodsReceivedSynced: false,
    goodsReceivedSyncFailed: false,
    ontvangsten: [],
    ontvangstList: [],
    list: [],
    voltooideOntvangsten: [],
    voltooideList: []
};

export default (state = initialState, action) => {
    switch(action.type) {
        // case "GET_ARTICLES_SUCCESS":
        //
        //     let myData = { articles:  action.payload };
        //     let article = new schema.Entity('articles', {}, {idAttribute: 'itemcode'}),
        //         mySchema = { articles: [ article ] },
        //         normalizedData = normalize(myData, mySchema);
        //
        //     return {
        //         ...state,
        //         articles: normalizedData,
        //     };
        case "POST_GOODS_RECEIVED_SUCCESS":

            console.log('reducer', action.payload);
            let ontvangstList = [...state.ontvangsten];
            ontvangstList[action.payload.id] = action.payload;

            return {
                ...state,
                ontvangstID: action.payload.id,
                ontvangsten: ontvangstList
            };
        case "GET_GOODSRECEIVED_SUCCESS":

            let payload = {ontvangstList: action.payload} ,
                item = new schema.Entity('ontvangsten'),
                itemSchema = { ontvangstList: [ item ] };

            return {
                ...state,
                ontvangstList: action.payload,
                list: normalize(payload, itemSchema),
            };
        case "GET_GOODSRECEIVED_DONE_SUCCESS":

            let list = {voltooideList: action.payload} ,
                ontvangstenVoltooid = new schema.Entity('ontvangstenVoltooid'),
                ontvangstSchema = { voltooideList: [ ontvangstenVoltooid ] };

            return {
                ...state,
                voltooideOntvangsten: action.payload,
                voltooideList: normalize(list, ontvangstSchema),
            };
        case "SYNC_GOODSRECEIVED_SUCCESS":
            return {
                ...state,
                goodsReceivedSynced: true
            };
        case "SYNC_GOODSRECEIVED_FAILED":
            return {
                ...state,
                goodsReceivedSynced: false,
                goodsReceivedSyncFailed: true
            };
        case "EDIT_GOODS_RECEIVED_SUCCESS":
            return {
                ...state,
                goodsReceived: action.payload
            };
        case "SYNC_ARTICLES_SUCCESS":
            return {
                ...state,
            };


        case ontvangstActionTypes.ADD_ONTVANGST:
            return {
                ...state,
            };
        case ontvangstActionTypes.SEND_ONTVANGST_SUCCESS:
            return {
                ...state,
            };
        default:
            return state;
    }
}